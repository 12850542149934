import React from 'react';
import { Button } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { HOMEPAGE_FUNEL_SECTION } from 'static_pages/home/redesign/components/utils';

const H2Title = styled.h2`
  margin: 0;
  color: #3c4340;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 30px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 36px;
  }
`;

const H3Subtitle = styled.h3`
  margin: 0;
  color: #3c4340;

  font-size: 16px;
  font-weight: 600;
  line-height: 160%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-weight: 400;
  }
`;

const H3ThinSubtitle = styled.h3`
  margin: 0;
  color: #3c4340;

  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 16px;
  }
`;
const StyledButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing(1.5, 4)};
  font-size: 16px;
`;

const CTAButton = () => {
  const { t } = useTranslation('static_pages');

  return (
    <StyledButton
      rounded
      variant="primary"
      component="a"
      href={`#${HOMEPAGE_FUNEL_SECTION}`}
    >
      {t('homepageRedesign.ctaLabel')}
    </StyledButton>
  );
};

export const HomepageRedesign = {
  H2: H2Title,
  H3: H3Subtitle,
  Subtitle: H3ThinSubtitle,
  CTAButton,
  Button: StyledButton,
};
