import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  count: number;
  activeIndex: number;
  onClick?: (index: number) => void;
  size?: string;
  color?: string;
  activeColor?: string;
  border?: string;
}

const Dots = ({
  count,
  activeIndex,
  onClick,
  size = '11px',
  color = '#E3E4E4',
  border,
  activeColor = '#3C4340',
}: Props) => {
  return (
    <Wrapper>
      {new Array(count).fill(1).map((_, index) => (
        <Dot
          as={onClick ? 'button' : 'div'}
          type="button"
          aria-label={`select slide: ${index + 1}`}
          onClick={onClick ? () => onClick(index) : undefined}
          key={index}
          $active={activeIndex === index}
          $size={size}
          $color={color}
          $activeColor={activeColor}
          $isButton={!!onClick}
          $border={border}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
  justify-content: center;
`;

interface DotProps {
  $active: boolean;
  $size: string;
  $activeColor: string;
  $color?: string;
  $isButton?: boolean;
  $border?: string;
}
const Dot = styled.div<DotProps>`
  ${({
    $active,
    $size,
    $activeColor,
    $color,
    $isButton,
    $border = 'none',
  }) => css`
    background-color: ${$active ? $activeColor : $color};
    border-radius: 50%;
    flex-shrink: 0;
    border: ${$border};
    padding: 0;
    box-sizing: border-box;
    transition: background-color 0.1s ease-out;
    width: ${$size};
    height: ${$size};

    cursor: ${$isButton ? 'pointer' : 'auto'};

    &:hover: {
      background-color: ${$isButton ? $activeColor : $color};
    }
  `}
`;

export default Dots;
