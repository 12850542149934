import React from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import { Entry } from 'contentful';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';

import { getContentfulImageUrl } from 'hub/helpers/contentful';
import { EXPERIMENT } from 'lib/ablyft';
import { IBlogArticleFields } from 'lib/types/generated/contentful';
import { useBreakpointUp } from 'qualification/hooks/useBreakpoints';
import { useVariationActive } from 'qualification/hooks/useVariationActive';
import Dots from 'shared/components/Dots';
import { usePagination } from 'shared/hooks/usePagination';
import InspirationCard from 'static_pages/home/components/InspirationSection/InspirationCard';
import { HomepageRedesign } from 'static_pages/home/redesign/components/Shared';
import DarkButton from 'system/components/Button/DarkButton';

type Props = {
  posts: Entry<IBlogArticleFields>[];
};

const InspirationsSection = ({ posts }: Props) => {
  const mdUp = useBreakpointUp('md');
  const smUp = useBreakpointUp('sm');

  const { t } = useTranslation('static_pages');
  const paginatedPosts = usePagination(posts, { pageSize: 1 });

  const swipeHandlers = useSwipeable({
    onSwipedLeft: paginatedPosts.next,
    onSwipedRight: paginatedPosts.prev,
  });

  const post = paginatedPosts.currentPage[0];
  const isPaginated = !smUp;
  const isHomepageRedesignActive = useVariationActive(
    EXPERIMENT.homepageRedesign.variations.variation
  );

  if (!posts.length) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {isHomepageRedesignActive ? (
        <>
          <Box mb={{ xs: 1, md: 2 }}>
            <HomepageRedesign.H2>
              {t('homepage.inspiration.title')}
            </HomepageRedesign.H2>
          </Box>
          <Box mb={{ xs: 4, md: 3 }}>
            <HomepageRedesign.H3>
              {' '}
              {t('homepage.inspiration.description')}
            </HomepageRedesign.H3>
          </Box>
        </>
      ) : (
        <>
          <Title component="h3" mb={{ xs: 1, md: 2 }}>
            {t('homepage.inspiration.title')}
          </Title>
          <Subtitle component="h4" mb={{ xs: 4, md: 3 }}>
            {t('homepage.inspiration.description')}
          </Subtitle>
        </>
      )}

      <Box mb={{ xs: 3, sm: 6 }}>
        {isPaginated ? (
          <Box {...swipeHandlers}>
            <InspirationCard
              description={post.fields.description}
              title={post.fields.title}
              href={`/hub/${post.fields.hubslug}`}
              imageSrc={getContentfulImageUrl(post.fields.heroImage)}
            />
            <Box mt={3}>
              <Dots
                count={paginatedPosts.pageCount}
                activeIndex={paginatedPosts.pageIndex}
                onClick={paginatedPosts.setPageIndex}
              />
            </Box>
          </Box>
        ) : (
          <CardListWrapper>
            {posts.slice(0, mdUp ? 3 : 2).map((_post) => (
              <InspirationCard
                key={_post.sys.id}
                description={_post.fields.description}
                title={_post.fields.title}
                href={`/hub/${_post.fields.hubslug}`}
                imageSrc={getContentfulImageUrl(_post.fields.heroImage)}
              />
            ))}
          </CardListWrapper>
        )}
      </Box>
      <Link href="/hub" passHref>
        {isHomepageRedesignActive ? (
          <StyledButton variant="tertiary" component="a" rounded outline>
            {t('homepage.inspiration.more')}
          </StyledButton>
        ) : (
          <DarkButton component="a" rounded outline>
            {t('homepage.inspiration.more')}
          </DarkButton>
        )}
      </Link>
    </Box>
  );
};

const StyledButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing(1.5, 3)};
`;

const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  line-height: 170%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 32px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 36px;
  }
`;

const Subtitle = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  line-height: 168%;
`;

const CardListWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: ${({ theme }) => theme.spacing(4)}px;
  }
`;

export default InspirationsSection;
