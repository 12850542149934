import React from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import truncate from 'lodash/truncate';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { createSizes } from 'shared/helpers/createSizes';

interface CardProps {
  imageSrc: string;
  title: string;
  description: string;
  href: string;
}
const InspirationCard = ({ imageSrc, title, description, href }: CardProps) => {
  const { t } = useTranslation('common');
  return (
    <Link href={href} passHref>
      <CardWrapper as="a">
        <Box
          height={{ xs: '210px', sm: 210, md: 210 }}
          width="100%"
          position="relative"
          borderRadius={8}
          overflow="hidden"
        >
          <Image
            alt=""
            layout="fill"
            height="210px"
            width="350px"
            src={imageSrc}
            objectFit="cover"
            sizes={createSizes({ xs: '90vw', sm: '350px' })}
          />
        </Box>
        <CardTitle component="h5">{title}</CardTitle>
        <CardDescription>
          {truncate(description, { length: 150 })}
        </CardDescription>
        <Box mt="auto">
          <Link href={href} passHref>
            <ButtonLink component="a" rounded={false} variant="tertiary">
              {t('action.readMore')}
              <span className="sr-only">: {title}</span>
            </ButtonLink>
          </Link>
        </Box>
      </CardWrapper>
    </Link>
  );
};

const CardWrapper = styled.div`
  width: 100%;
  text-decoration: none;
  color: inherit;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 350px;
  }

  img {
    width: 100%;
    display: block;
    position: relative;
    transition: transform 0.5s ease, filter 0.5s ease;
  }

  &:hover img {
    filter: brightness(0.9);
    transform: scale(1.1);
  }
`;

const CardDescription = styled(Typography)`
  font-size: 14px;
  line-height: 168%;

  color: #3c4340;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 16px;
  }
`;

const CardTitle = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  line-height: 168%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 18px;
  }
`;

const ButtonLink = styled(Button)`
  text-transform: inherit;
  padding: ${(props) => props.theme.spacing(1, 2)};
  font-weight: 500;
  line-height: ${({ theme }) => theme.spacing(3)}px;
  letter-spacing: 0.15px;
  font-size: 14px;
`;
export default InspirationCard;
