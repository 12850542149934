import { useCallback, useMemo, useState } from 'react';

import { chunk } from 'shared/helpers/array';

interface PaginationOptions {
  pageSize: number;
  initialPage?: number;
  limit?: number;
}

export const usePagination = <T>(
  items: T[],
  { pageSize, initialPage, limit }: PaginationOptions
) => {
  const [pageIndex, setPageIndex] = useState(initialPage || 0);

  const paginatedItems = useMemo(
    () => chunk(limit ? items.slice(0, limit) : items, pageSize),
    [items, limit, pageSize]
  );

  const lastPageIndex = paginatedItems.length - 1;

  const next = useCallback(
    () => setPageIndex((prev) => (prev + 1) % paginatedItems.length),
    [pageSize]
  );

  const prev = useCallback(
    () => setPageIndex((prev) => (prev - 1 === -1 ? lastPageIndex : prev - 1)),
    [pageSize]
  );

  return {
    pages: paginatedItems,

    currentPage: paginatedItems[pageIndex],
    nextPage: paginatedItems[pageIndex + 1],
    prevPage: paginatedItems[pageIndex - 1],

    pageCount: paginatedItems.length,
    pageIndex: pageIndex,

    next,
    prev,
    setPageIndex,
  };
};
