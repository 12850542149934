import { BreakpointValues } from '@material-ui/core/styles/createBreakpoints';

import theme from 'system/theme';

type SizesOptions = Partial<Record<keyof BreakpointValues, string>> & {
  xs: string;
};

/**
 * Utility function that helps defining the `sizes` property, of the `next/image` component
 * Based on the theme's breakpoints
 */
export const createSizes = (options: SizesOptions) => {
  const optionsAsEntries = Object.entries(options) as [
    keyof BreakpointValues,
    string
  ][];

  // Sorting, so it goes from the smallest breakpoint to the biggest. (mobile-first approach)
  const sortedOptionEntries = optionsAsEntries.sort(
    ([breakpointKeyA], [breakpointKeyB]) => {
      const breakpointA = theme.breakpoints.values[breakpointKeyA];
      const breakpointB = theme.breakpoints.values[breakpointKeyB];
      if (breakpointA > breakpointB) {
        return -1;
      }
      if (breakpointA === breakpointB) {
        return 0;
      }

      return 1;
    }
  );

  const sizesArray = sortedOptionEntries.map(
    ([breakpointKey, size]) =>
      `(min-width: ${
        theme.breakpoints.values[breakpointKey as keyof BreakpointValues]
      }px) ${size}`
  );

  return sizesArray.join(', ');
};
